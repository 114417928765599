import React, { useState, useEffect } from "react";
import Layout from "@components/layouts/Layout";
import Seo from "@components/common/Seo";
import SmallBanner from "@components/common/SmallBanner";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
import Products from "@components/sections/Products";
import dayjs from "dayjs";
import axios from "axios";
import useSWR from "swr";
import ProductsNew from "@components/sections/products2/Products";
import { TbCircleNumber1, TbCircleNumber2, TbCircleNumber3, TbCircleNumber4 } from "react-icons/tb";

const endpoints = {
    products: "/.netlify/functions/products",
};

const Tienda = () => {
    const {
        data: result,
        isValidating,
        isLoading,
    } = useSWR(endpoints.products, axios.get, {
        dedupingInterval: 30 * 1000,
    });

    const [filteredItems, setFilteredItems] = useState([]);

    const [mossosF1P, setMossosF1P] = useState([]);
    const [mossosF2P, setMossosF2P] = useState([]);
    const [mossosF3P, setMossosF3P] = useState([]);
    const [mossosF4P, setMossosF4P] = useState([]);

    const [mossosF1O, setMossosF1O] = useState([]);
    const [mossosF2O, setMossosF2O] = useState([]);
    const [mossosF3O, setMossosF3O] = useState([]);
    const [mossosF4O, setMossosF4O] = useState([]);

    // const [mossosOnlineItems, setMossosOnlineItems] = useState([]);
    // const [mossosPresencialItems, setMossosPresencialItems] = useState([]);
    // const [plOnlineItems, setPlOnlineItems] = useState([]);
    const [plPresencialItems, setPlPresencialItems] = useState([]);

    useEffect(() => {
        // Filtramos y ordenamos los productos
        const items = result?.data?.products
            ?.filter(x => x.enabled && !x.hidden)
            // Convertimos fechas
            .map(p => {
                const { created_at, updated_at, ...rest } = p;
                return {
                    ...rest,
                    created_at: dayjs(created_at).toDate(),
                    updated_at: dayjs(updated_at).toDate(),
                };
            })
            // Ordenamos por stock y luego por fecha
            .sort((a, b) => b.stock - a.stock || b.created_at - a.created_at);

        setFilteredItems(items);
    }, [result]);

    useEffect(() => {
        setMossosF1P(
            filteredItems?.filter(
                x =>
                    x.category?.includes("mossos") &&
                    x.category?.includes("face-to-face") &&
                    x.category.includes("interviews") &&
                    x.category?.includes("fase1"),
            ) || [],
        );

        setMossosF2P(
            filteredItems?.filter(
                x =>
                    x.category?.includes("mossos") &&
                    x.category?.includes("face-to-face") &&
                    x.category.includes("interviews") &&
                    x.category?.includes("fase2"),
            ) || [],
        );

        setMossosF3P(
            filteredItems?.filter(
                x =>
                    x.category?.includes("mossos") &&
                    x.category?.includes("face-to-face") &&
                    x.category.includes("interviews") &&
                    x.category?.includes("fase3"),
            ) || [],
        );

        setMossosF4P(
            filteredItems?.filter(
                x =>
                    x.category?.includes("mossos") &&
                    x.category?.includes("face-to-face") &&
                    x.category.includes("interviews") &&
                    x.category?.includes("fase4"),
            ) || [],
        );

        setMossosF1O(
            filteredItems?.filter(
                x =>
                    x.category?.includes("mossos") &&
                    x.category?.includes("online") &&
                    x.category.includes("interviews") &&
                    x.category?.includes("fase1"),
            ) || [],
        );

        setMossosF2O(
            filteredItems?.filter(
                x =>
                    x.category?.includes("mossos") &&
                    x.category?.includes("online") &&
                    x.category.includes("interviews") &&
                    x.category?.includes("fase2"),
            ) || [],
        );

        setMossosF3O(
            filteredItems?.filter(
                x =>
                    x.category?.includes("mossos") &&
                    x.category?.includes("online") &&
                    x.category.includes("interviews") &&
                    x.category?.includes("fase3"),
            ) || [],
        );

        setMossosF4O(
            filteredItems?.filter(
                x =>
                    x.category?.includes("mossos") &&
                    x.category?.includes("online") &&
                    x.category.includes("interviews") &&
                    x.category?.includes("fase4"),
            ) || [],
        );

        setPlPresencialItems(
            filteredItems?.filter(
                x =>
                    x.category?.includes("pl") &&
                    x.category?.includes("face-to-face") &&
                    x.category.includes("interviews"),
            ) || [],
        );
    }, [filteredItems]);

    return (
        <>
            <Layout>
                <Seo
                    title="Nuestra tienda: Entrevistas"
                    description="Sabemos quien te hace la entrevista en cada proceso, conocemos qué casos te va a poner y qué preguntas. Tenemos la información de todas las policías para que saques la máxima nota posible en la entrevista."
                />
                <SmallBanner
                    title="Entrevistas"
                    alt="Entrevistas"
                    subtitle="Preparación personalizada y específica para cada municipio"
                    filename="bgs/bgEntrevistas3"
                />

                <div className="container lg:px-0 py-20 space-y-10">
                    {/** Mossos Presenciales */}
                    <section className="bg-gray-50">
                        <Title>Mossos d'Esquadra - Presenciales</Title>
                        <Text className="text-justify">
                            Sabemos que tipologia de entrevista tendrás y qué busca exactamente el
                            tribunal. En nuestro curso presencial de preparación a Mossos d'Esquadra
                            te preparamos con simulaciones reales y feedback personalizado.
                            Conocemos cada detalle del proceso de selección para ayudarte a destacar
                            y conseguir la máxima puntuación.
                        </Text>

                        <div className="grid grid-cols-1 gap-10">
                            {/** Fase 1 */}
                            <div className="space-y-3">
                                <h3 className="font-semibold text-ccyan-dark text-xl mt-10 border-b-2 border-ccyan border-opacity-75 uppercase flex items-center gap-2">
                                    <span>Fase</span>
                                    <TbCircleNumber1 />
                                </h3>

                                <Text className="text-justify text-sm">
                                    Se realiza un curso presencial sobre biodata, currículum y tests
                                    psicoprofesionales, seguido de una sesión en directo por Zoom
                                    con un psicólogo para resolver dudas clave. Esta sesión queda
                                    grabada en el campus y los cursos presenciales se imparten en
                                    fines de semana.
                                </Text>

                                <div className="mt-10 space-y-5">
                                    {process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true" ? (
                                        <ProductsNew
                                            items={mossosF1P}
                                            loading={isLoading || isValidating}
                                        />
                                    ) : (
                                        <Products items={mossosF1P} />
                                    )}
                                </div>
                            </div>

                            {/** Fase 2 */}
                            <div className="space-y-3">
                                <h3 className="font-semibold text-ccyan-dark text-xl mt-10 border-b-2 border-ccyan border-opacity-75 uppercase flex items-center gap-2">
                                    <span>Fase</span>
                                    <TbCircleNumber2 className="text-2xl" />
                                </h3>

                                <Text className="text-justify text-sm">
                                    La preparación para la entrevista policial es presencial e
                                    individual, con una duración de 1 hora y 30 minutos, guiada por
                                    un especialista. Se trabaja la comunicación, el
                                    autoconocimiento, el currículum y la motivación, con análisis
                                    personalizado y feedback final.
                                </Text>

                                <div className="mt-16 space-y-5">
                                    {process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true" ? (
                                        <ProductsNew
                                            items={mossosF2P}
                                            loading={isLoading || isValidating}
                                        />
                                    ) : (
                                        <Products items={mossosF2P} />
                                    )}
                                </div>
                            </div>

                            {/** Fase 3 */}
                            <div className="space-y-3">
                                <h3 className="font-semibold text-ccyan-dark text-xl mt-10 border-b-2 border-ccyan border-opacity-75 uppercase flex items-center gap-2">
                                    <span>Fase</span>
                                    <TbCircleNumber3 className="text-2xl" />
                                </h3>

                                <Text className="text-justify text-sm">
                                    Consiste en un simulacro presencial e individual de una hora,
                                    donde se te evaluará desde el primer minuto como si fuera la
                                    entrevista oficial. Se trabaja con preguntas reales y
                                    competencias de la convocatoria, incluyendo un feedback final
                                    para corregir errores.
                                </Text>

                                <div className="mt-10 space-y-5">
                                    {process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true" ? (
                                        <ProductsNew
                                            items={mossosF3P}
                                            loading={isLoading || isValidating}
                                        />
                                    ) : (
                                        <Products items={mossosF3P} />
                                    )}
                                </div>
                            </div>

                            {/** Fase 4 */}
                            <div className="space-y-3">
                                <h3 className="font-semibold text-ccyan-dark text-xl mt-10 border-b-2 border-ccyan border-opacity-75 uppercase flex items-center gap-2">
                                    <span>Fase</span>
                                    <TbCircleNumber4 />
                                </h3>

                                <Text className="text-justify text-sm">
                                    Simulacro premium presencial de una hora con evaluación
                                    individual a cargo de un psicólogo y un caporal de Mossos
                                    especialista en selección. Se utiliza una parrilla oficial de
                                    puntuación por competencias, con preguntas reales y feedback
                                    final detallado.
                                </Text>

                                <div className="space-y-5 mt-10">
                                    {process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true" ? (
                                        <ProductsNew
                                            items={mossosF4P}
                                            loading={isLoading || isValidating}
                                        />
                                    ) : (
                                        <Products items={mossosF4P} />
                                    )}
                                </div>
                            </div>
                        </div>
                    </section>

                    {/** Mossos Online */}
                    <section className="bg-gray-50">
                        <Title>Mossos d'Esquadra - Online</Title>
                        <Text className="text-justify">
                            Sabemos que tipologia de entrevista tendrás y qué busca exactamente el
                            tribunal. En nuestro curso presencial de preparación a Mossos d'Esquadra
                            te preparamos con simulaciones reales y feedback personalizado.
                            Conocemos cada detalle del proceso de selección para ayudarte a destacar
                            y conseguir la máxima puntuación.
                        </Text>

                        <div className="grid grid-cols-1 gap-10">
                            {/** Fase 1 */}
                            <div className="space-y-3">
                                <h3 className="font-semibold text-ccyan-dark text-xl mt-10 border-b-2 border-ccyan border-opacity-75 uppercase flex items-center gap-2">
                                    <span>Fase</span>
                                    <TbCircleNumber1 />
                                </h3>

                                <Text className="text-justify text-sm">
                                    Se realiza un curso presencial sobre biodata, currículum y tests
                                    psicoprofesionales, seguido de una sesión en directo por Zoom
                                    con un psicólogo para resolver dudas clave. Esta sesión queda
                                    grabada en el campus y los cursos presenciales se imparten en
                                    fines de semana.
                                </Text>

                                <div className="mt-10 space-y-5">
                                    {process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true" ? (
                                        <ProductsNew
                                            items={mossosF1O}
                                            loading={isLoading || isValidating}
                                        />
                                    ) : (
                                        <Products items={mossosF1O} />
                                    )}
                                </div>
                            </div>

                            {/** Fase 2 */}
                            <div className="space-y-3">
                                <h3 className="font-semibold text-ccyan-dark text-xl mt-10 border-b-2 border-ccyan border-opacity-75 uppercase flex items-center gap-2">
                                    <span>Fase</span>
                                    <TbCircleNumber2 className="text-2xl" />
                                </h3>

                                <Text className="text-justify text-sm">
                                    La preparación para la entrevista policial es presencial e
                                    individual, con una duración de 1 hora y 30 minutos, guiada por
                                    un especialista. Se trabaja la comunicación, el
                                    autoconocimiento, el currículum y la motivación, con análisis
                                    personalizado y feedback final.
                                </Text>

                                <div className="mt-16 space-y-5">
                                    {process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true" ? (
                                        <ProductsNew
                                            items={mossosF2O}
                                            loading={isLoading || isValidating}
                                        />
                                    ) : (
                                        <Products items={mossosF2O} />
                                    )}
                                </div>
                            </div>

                            {/** Fase 3 */}
                            <div className="space-y-3">
                                <h3 className="font-semibold text-ccyan-dark text-xl mt-10 border-b-2 border-ccyan border-opacity-75 uppercase flex items-center gap-2">
                                    <span>Fase</span>
                                    <TbCircleNumber3 className="text-2xl" />
                                </h3>

                                <Text className="text-justify text-sm">
                                    Consiste en un simulacro presencial e individual de una hora,
                                    donde se te evaluará desde el primer minuto como si fuera la
                                    entrevista oficial. Se trabaja con preguntas reales y
                                    competencias de la convocatoria, incluyendo un feedback final
                                    para corregir errores.
                                </Text>

                                <div className="mt-10 space-y-5">
                                    {process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true" ? (
                                        <ProductsNew
                                            items={mossosF3O}
                                            loading={isLoading || isValidating}
                                        />
                                    ) : (
                                        <Products items={mossosF3O} />
                                    )}
                                </div>
                            </div>

                            {/** Fase 4 */}
                            <div className="space-y-3">
                                <h3 className="font-semibold text-ccyan-dark text-xl mt-10 border-b-2 border-ccyan border-opacity-75 uppercase flex items-center gap-2">
                                    <span>Fase</span>
                                    <TbCircleNumber4 />
                                </h3>

                                <Text className="text-justify text-sm">
                                    Simulacro premium presencial de una hora con evaluación
                                    individual a cargo de un psicólogo y un caporal de Mossos
                                    especialista en selección. Se utiliza una parrilla oficial de
                                    puntuación por competencias, con preguntas reales y feedback
                                    final detallado.
                                </Text>

                                <div className="space-y-5 mt-10">
                                    {process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true" ? (
                                        <ProductsNew
                                            items={mossosF4O}
                                            loading={isLoading || isValidating}
                                        />
                                    ) : (
                                        <Products items={mossosF4O} />
                                    )}
                                </div>
                            </div>
                        </div>
                    </section>

                    {/** PL Presenciales */}
                    <section className="bg-gray-50">
                        <Title>Policia Local Presenciales</Title>
                        <Text className="text-justify">
                            Domina la entrevista con nuestra preparación cara a cara. Sabemos cómo
                            piensa el tribunal y cuáles son las preguntas más frecuentes. En
                            nuestras clases presenciales para Policía Local, trabajarás con
                            simulacros realistas y recibirás correcciones inmediatas. Entrena en
                            condiciones reales y llega a la entrevista con seguridad y ventaja.
                        </Text>

                        <div className="mt-10 space-y-5">
                            {process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true" ? (
                                <ProductsNew
                                    items={plPresencialItems}
                                    loading={isLoading || isValidating}
                                />
                            ) : (
                                <Products items={plPresencialItems} />
                            )}
                        </div>
                    </section>
                </div>
            </Layout>
        </>
    );
};

export default Tienda;
